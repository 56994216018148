import { defineStore } from "pinia";
import { isNotNullOrUndefined } from "~/util/typeGuards";

function createKey(serviceId?: string | null, userId?: string | null): string {
  let key = "";

  if (isNotNullOrUndefined(serviceId)) {
    key = serviceId;
  }

  if (isNotNullOrUndefined(userId)) {
    key = key !== "" ? `${key}:${userId}` : userId;
  }

  return key;
}

export const useSlotRequestCacheKeysStore = defineStore("slotRequestCacheKeys", () => {
  const slotStore = new Map<string, string>();

  function setKey(serviceId: string | null | undefined, userId: string | null | undefined, cacheKey: string): void {
    const key = createKey(serviceId, userId);

    slotStore.set(key, cacheKey);
  }

  function getKey(serviceId?: string | null, userId?: string | null): string | undefined {
    const key = createKey(serviceId, userId);

    return slotStore.get(key);
  }

  return {
    getKey,
    setKey,
    slotStore,
  };
});
