import { getStartOfDay } from "~/util/dates/getStartOfDay";

/**
 * If you edit this file please also reflect the changes in
 * `backend/src/utils/dates/getEndOfDay.ts`
 */
export function getEndOfDay(date: Date): Date {
  const twentyFourHoursInMilliseconds = 86400000;
  const startOfDayInMilliseconds = getStartOfDay(date).getTime();

  const endOfLastDayMilliseconds = startOfDayInMilliseconds + twentyFourHoursInMilliseconds - 1;

  return new Date(endOfLastDayMilliseconds);
}
